import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { VesselVisitDisplayModel } from 'app/vessel-visit/vessel-visit-list/models/VesselVisit.display.model';
import { Observable } from 'rxjs';

@Injectable()
export class VesselVisitService {
    constructor(private http: HttpClient) {}

    sendPortFacilityApprovalToCimis(vesselVisitId: number, portFacilities: any[]): Observable<any> {
        const body = {
            vesselVisitId,
            portFacilities
        };
        return this.http.post<any>('api:///cimis/ApprovePortFacility', body);
    }

    getLkcActions(): Observable<any> {
        return this.http.get('api:///VesselVisit/LkcActions');
    }

    getVesselVisitList(filter: any): Observable<any> {
        return this.http.post('api:///VesselVisit/VesselVisitList', filter);
    }

    getSingleVisit(vesselVisitId: number): Observable<VesselVisitDisplayModel> {
        return this.http.get<any>(`api:///VesselVisit/GetSingleVisit/${vesselVisitId}`);
    }

    getSingleVisitApprovalsWaiting(vesselVisitId: number) {
        return this.http.get<any>(`api:///VesselVisit/GetSingleVisitApprovalsWaiting/${vesselVisitId}`);
    }

    getPortFacilitiesForPortOfCall(query: any): Observable<any> {
        return this.http.post<any>('api:///VesselVisit/GetPortFacilitiesForPortOfCall', query);
    }

    getNominatedAgentList(filter: any, vesselVisitId: number): Observable<any> {
        return this.http.post<any>(`api:///VesselVisit/NominatedAgentList/${vesselVisitId}`, filter);
    }

    getPcsPortFacilityIdForRequestedPortFacility(
        localizedPortFacilityCode: string,
        portOfCall: string
    ): Observable<any> {
        const body = { localizedPortFacilityCode, portOfCall };
        return this.http.post<any>('api:///VesselVisit/GetPcsPortFacilityId', body);
    }

    getServiceRequestsForVesselVisit(vesselVisitId: number): Observable<any> {
        return this.http.get<any>(`api:///VesselVisit/GetServiceRequestsForVesselVisit/${vesselVisitId}`);
    }

    addNominatedAgents(vesselVisitId: number, agentsToAdd: any[]): Observable<any> {
        const body = {
            vesselVisitId,
            agentsToAdd
        };
        return this.http.post<any>('api:///VesselVisit/AddNominatedAgents', body);
    }

    vesselAgentConfirmVisit(vesselVisitId: number) {
        return this.http.post<any>(`api:///VesselVisit/VesselAgentConfirmVisit/${vesselVisitId}`, null);
    }

    removeNominatedAgent(nominatedAgentId: number): Observable<any> {
        return this.http.post<any>(`api:///VesselVisit/DeactivateNominatedAgent/${nominatedAgentId}`, null);
    }

    getTosList(filter: any): Observable<any> {
        return this.http.post<any>('api:///VesselVisit/GetTosList', filter);
    }

    addTosToNominatedAgent(nominatedAgentId: number, tosCode: string): Observable<any> {
        const body = { nominatedAgentId, tosCode };
        return this.http.post<any>('api:///VesselVisit/AddTosToNominatedAgent', body);
    }

    getShippingLineList(filter: any): Observable<any> {
        return this.http.post<any>('api:///VesselVisit/GetShippingLineList', filter);
    }

    addShippingLinesToNominatedAgent(nominatedAgentId: number, shippingLineId: string): Observable<any> {
        const body = { nominatedAgentId, shippingLineId };
        return this.http.post<any>('api:///VesselVisit/AddShippingLinesToNominatedAgent', body);
    }

    deactivateNominatedAgentShippingLine(nominatedAgentShippingLineId: number): Observable<any> {
        return this.http.post<any>(
            `api:///VesselVisit/DeactivateNominatedAgentShippingLine/${nominatedAgentShippingLineId}`,
            null
        );
    }

    addTosVoyageNumberToShippingLine(nominatedAgentShippingLineId: number, tosVoyageNumber: string): Observable<any> {
        const body = { nominatedAgentShippingLineId, tosVoyageNumber };
        return this.http.post<any>('api:///VesselVisit/AddTosVoyageNumberToShippingLine', body);
    }

    assignNavisVesselVisitDataToPcsVisit(navisData: any, pcsVesselVisitId: number) {
        const body = {
            navisVesselVisitId: navisData.navisVesselVisitId,
            masterInVoyageNumber: navisData.masterInVoyageNumber,
            masterOutVoyageNumber: navisData.masterOutVoyageNumber,
            pcsVesselVisitId: pcsVesselVisitId
        };

        return this.http.post<any>('api:///VesselVisit/AssignNavisVesselVisitDataToPcsVesselVisit', body);
    }

    resetNavisAndPcsVesselVisitBinding(pcsVesselVisitId: number): Observable<any> {
        return this.http.post<any>(`api:///VesselVisit/ResetNavisAndPcsVesselVisitBinding/${pcsVesselVisitId}`, null);
    }

    checkDuplicateShippingLine(nominatedAgentId: number, shippingLineId: string): Observable<any> {
        return this.http.get<any>(
            `api:///VesselVisit/CheckDuplicateShippingLine/${nominatedAgentId}/${shippingLineId}`
        );
    }

    sendAtaEntry(model) {
        return this.http.post<any>('api:///VesselVisit/sendAtaEntry', model);
    }

    sendAtdEntry(model) {
        return this.http.post<any>('api:///VesselVisit/sendAtdEntry', model);
    }

    setVesselAcceptance(model) {
        return this.http.post<any>('api:///VesselProcedure/SetVesselAcceptance', model);
    }

    getVesselVisitVersions(id) {
        return this.http.get<any[]>(`api:///VesselVisit/GetVesselVisitVersions/${id}`);
    }

    changeAtaAtd(model: any) {
        return this.http.post(`api:///VesselVisit/SetNewAtaOrAtd`, model);
    }

    getVesselVisitAttachments(vesselVisitId: number): Observable<any> {
        return this.http.get<any>(`api:///VesselVisit/GetVesselVisitAttachments/${vesselVisitId}`);
    }

    addVesselVisitAttachment(model: any) {
        return this.http.post<any>(`api:///VesselVisit/AddVesselVisitAttachment`, model);
    }

    removeVesselVisitAttachment(ids: number[]) {
        return this.http.post<any>(`api:///VesselVisit/RemoveVesselVisitAttachment`, ids);
    }

    getVehicleList(id) {
        return this.http.get<any[]>(`api:///VesselVisit/GetVehicleList/${id}`);
    }

    getTerminalOptionsForVehicles(vesselVisitId) {
        return this.http.get<any[]>(`api:///VesselVisit/GetTerminalOptionsForVehicleList/${vesselVisitId}`);
    }

    saveVehicle(model: any) {
        return this.http.post<any>(`api:///VesselVisit/SaveVehicle`, model);
    }

    removeVehicle(ids: any) {
        return this.http.post<any>(`api:///VesselVisit/RemoveVehicle/`, ids);
    }
}
