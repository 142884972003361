import { registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import localeBg from '@angular/common/locales/bg';
import localeDe from '@angular/common/locales/de';
import localeZa from '@angular/common/locales/en-ZA';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
    NavigationCancel,
    NavigationEnd,
    NavigationError,
    NavigationStart,
    Router,
    RouterModule
} from '@angular/router';
import { KendoMessageService } from '@common/services/kendoMessage.service';
import { StartupService } from '@common/services/startup.service';
import { UserService } from '@common/services/user.service';
import { NgbDropdownModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoadingBarModule, LoadingBarService } from '@ngx-loading-bar/core';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { IntlModule } from '@progress/kendo-angular-intl';
import '@progress/kendo-angular-intl/locales/bg/all';
import '@progress/kendo-angular-intl/locales/en-ZA/all';
import { MessageService } from '@progress/kendo-angular-l10n';
import { UploadModule } from '@progress/kendo-angular-upload';
import { AuthModule, OidcConfigService } from 'angular-auth-oidc-client';
import { DatetimerangepickerModule } from 'angular-datetimerangepicker';
import { UserIdleModule } from 'angular-user-idle';
import { ToastrModule } from 'ngx-toastr';
import { AppComponent } from './app.component';
import { ROUTES } from './app.routing';
import { CommonModule } from './common/common.module';
import { GlobalErrorHandler } from './common/global-error-handler';
import { AuthGuard } from './common/guards/auth.guard';
import { AuthorizationGuard } from './common/guards/authorization.guard';
import { HomeComponent } from './home.component';

registerLocaleData(localeDe);
registerLocaleData(localeZa);
registerLocaleData(localeBg, 'bg');

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

export function appInitFactory(oidcConfigService: OidcConfigService, startupService: StartupService): () => any {
    return () => {
        startupService.init();
    };
}

@NgModule({
    declarations: [AppComponent, HomeComponent],
    imports: [
        BrowserModule,
        DatetimerangepickerModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot(),
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        LoadingBarModule,
        RouterModule.forRoot(ROUTES, {
            initialNavigation: 'disabled',
            relativeLinkResolution: 'legacy'
        }),

        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),

        CommonModule,
        UploadModule,

        DateInputsModule,
        InputsModule,

        IntlModule,
        AuthModule.forRoot(),
        NgbModule,
        NgbDropdownModule,
        UserIdleModule.forRoot({ idle: 46800, timeout: 46800, ping: 100 })
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        LoadingBarModule,
        BrowserAnimationsModule,
        RouterModule,
        DateInputsModule,
        InputsModule
    ],
    providers: [
        UserService,
        {
            provide: LOCALE_ID,
            deps: [UserService],
            useFactory: (userService: UserService) => {
                return userService.getUserLanguage() === 'bg' ? 'bg-BG' : 'en-ZA';
            }
        },
        { provide: MessageService, useClass: KendoMessageService },
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        OidcConfigService,
        StartupService,
        {
            provide: APP_INITIALIZER,
            useFactory: appInitFactory,
            deps: [OidcConfigService, StartupService],
            multi: true
        },
        DialogRef
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(
        private authGuard: AuthGuard,
        private authorizationGuard: AuthorizationGuard,
        private router: Router,
        private translate: TranslateService,
        private http: HttpClient,
        private loadingBarService: LoadingBarService
    ) {
        const routerRef = this.loadingBarService.useRef('router');
        router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                routerRef.start();
            } else if (event instanceof NavigationError) {
                routerRef.complete();
                console.error(event.error);
            } else if (event instanceof NavigationEnd) {
                routerRef.complete();
            } else if (event instanceof NavigationCancel) {
                routerRef.complete();
            }
        });

        const defaultLang = 'en';
        // this language will be used as a fallback when a translation isn't found in the current language
        translate.setDefaultLang(defaultLang);

        // the lang to use, if the lang isn't available, it will use the current loader to get them
        translate.use(defaultLang);
    }
}
