<app-loader [isBusy]="isBusy">
    <ng-template>
        <div class="modal-header">
            <h4 class="modal-title">{{ isEditMode ? 'Edit' : ('Add' | translate) }} {{ 'Cargo Item' | translate }}</h4>
            <button class="close" type="button" aria-label="Close" (click)="activeModal.close(null)">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <div class="modal-body p-0">
            <app-loader [isBusy]="false">
                <ng-template>
                    <form class="pt-3" [formGroup]="form">
                        <div *ngIf="containerized" class="disabledLabels">
                            <div class="row">
                                <div class="col-xl-3 col-sm-6 col-xs-12">
                                    <form-dropdown
                                        formControlName="shippingLineOrganizationId"
                                        labelText="{{ 'Shipping Line' | translate }}"
                                        [observableInput]="organizations$"></form-dropdown>
                                </div>
                                <div class="col-xl-3 col-sm-6 col-xs-12">
                                    <form-textbox
                                        formControlName="bookingNumber"
                                        labelText="{{ 'Booking Nbr.' | translate }}"></form-textbox>
                                </div>
                                <div class="col-xl-3 col-sm-6 col-xs-12">
                                    <form-textbox
                                        formControlName="billOfLadingNbr"
                                        labelText="{{ 'B/L Nbr.' | translate }}"></form-textbox>
                                </div>
                                <div class="col-xl-3 col-sm-6 col-xs-12">
                                    <form-textbox
                                        formControlName="containerNumber"
                                        labelText="{{ 'Container' | translate }}"></form-textbox>
                                </div>
                                <div class="col-xl-3 col-sm-6 col-xs-12">
                                    <form-dropdown
                                        formControlName="containerIsoCodeId"
                                        labelText="{{ 'ISO Code' | translate }}"
                                        codeListName="ContainerISOCode"></form-dropdown>
                                </div>
                                <div class="col-xl-3 col-sm-6 col-xs-12">
                                    <form-number
                                        formControlName="containerTareWeight"
                                        labelText="{{ 'Tare Weight (kg)' | translate }}"
                                        [decimal]="true"></form-number>
                                </div>
                                <div class="col-xl-3 col-sm-6 col-xs-12">
                                    <form-dropdown
                                        formControlName="containerStatusId"
                                        labelText="{{ 'Cont. Status' | translate }}"
                                        codeListName="FullOrEmpty"></form-dropdown>
                                </div>
                                <div class="col-xl-3 col-sm-6 col-xs-12">
                                    <form-dropdown
                                        formControlName="loadTypeId"
                                        labelText="{{ 'Container Load Type' | translate }}"
                                        codeListName="LoadType"></form-dropdown>
                                </div>
                                <div class="col-xl-3 col-sm-6 col-xs-12">
                                    <form-textbox
                                        formControlName="sealNumber"
                                        labelText="{{ 'Seal Number' | translate }}"></form-textbox>
                                </div>
                                <div class="col-xl-3 col-sm-6 col-xs-12">
                                    <form-dropdown
                                        formControlName="sealTypeId"
                                        labelText="{{ 'Seal Type' | translate }}"
                                        codeListName="SealType"></form-dropdown>
                                </div>
                                <div class="col-xl-3 col-sm-6 col-xs-12">
                                    <form-textbox
                                        formControlName="taricCode"
                                        labelText="{{ 'TARIC Code' | translate }}"></form-textbox>
                                </div>
                                <div class="col-xl-3 col-sm-6 col-xs-12">
                                    <form-textbox
                                        formControlName="cargoDescription"
                                        labelText="{{ 'Cargo Description' | translate }}"></form-textbox>
                                </div>
                                <div class="col-xl-3 col-sm-6 col-xs-12">
                                    <form-textbox
                                        formControlName="cargoReference"
                                        labelText="{{ 'Cargo Reference (Marking)' | translate }}"></form-textbox>
                                </div>
                                <div class="col-xl-3 col-sm-6 col-xs-12">
                                    <form-number
                                        formControlName="numberOfPackages"
                                        labelText="{{ 'PCK. Number' | translate }}"></form-number>
                                </div>
                                <div class="col-xl-3 col-sm-6 col-xs-12">
                                    <form-dropdown
                                        formControlName="packagingTypeId"
                                        labelText="{{ 'PCK. Type' | translate }}"
                                        codeListName="CodeListCargoPackagingTypePerSystemForManifest"
                                        [forTOSSystem]="'CUST'"></form-dropdown>
                                </div>
                                <div class="col-xl-3 col-sm-6 col-xs-12">
                                    <form-dropdown
                                        formControlName="cargoTypePerSystemId"
                                        labelText="{{ 'Cargo Type' | translate }}"
                                        codeListName="CodeListCargoTypePerSystemForManifest"
                                        [forTOSSystem]="'MSW'"></form-dropdown>
                                </div>
                                <div class="col-xl-3 col-sm-6 col-xs-12">
                                    <form-number
                                        formControlName="cargoNetWeight"
                                        labelText="{{ 'Net Weight (kg)' | translate }}"
                                        [decimal]="true"></form-number>
                                </div>
                                <div class="col-xl-3 col-sm-6 col-xs-12">
                                    <form-number
                                        formControlName="cargoGrossWeight"
                                        labelText="{{ 'Gross Weight (kg)' | translate }}"
                                        [decimal]="true"></form-number>
                                </div>
                                <div class="col-xl-3 col-sm-6 col-xs-12">
                                    <form-number
                                        formControlName="cargoVolume"
                                        labelText="{{ 'Volume (m3)' | translate }}"
                                        [decimal]="true"></form-number>
                                </div>
                                <div class="col-xl-3 col-sm-6 col-xs-12">
                                    <form-number
                                        formControlName="vgm"
                                        labelText="{{ 'VGM (kg)' | translate }}"
                                        [decimal]="true"></form-number>
                                </div>
                                <div class="col-xl-3 col-sm-6 col-xs-12">
                                    <form-checkbox
                                        labelText="{{ 'Dangerous Cargo' | translate }}"
                                        [value]="showIMDG"
                                        (click)="showIMDG = !showIMDG"></form-checkbox>
                                </div>
                                <div *ngIf="showIMDG" class="col-xl-3 col-sm-6 col-xs-12">
                                    <form-dropdown
                                        formControlName="imdgClassId"
                                        labelText="{{ 'IMDG Nbr.' | translate }}"
                                        codeListName="ImdgClass"></form-dropdown>
                                </div>
                                <div *ngIf="showIMDG" class="col-xl-3 col-sm-6 col-xs-12">
                                    <form-dropdown
                                        formControlName="unNumberId"
                                        labelText="{{ 'UN Nbr.' | translate }}"
                                        codeListName="UnNumber"></form-dropdown>
                                </div>
                                <div *ngIf="showIMDG" class="col-xl-3 col-sm-6 col-xs-12">
                                    <form-dropdown
                                        formControlName="packingGroupId"
                                        labelText="{{ 'Pack. Group' | translate }}"
                                        codeListName="ImdgPackingGroup"></form-dropdown>
                                </div>
                                <div *ngIf="showIMDG" class="col-xl-3 col-sm-6 col-xs-12">
                                    <form-textbox
                                        formControlName="flashpoint"
                                        labelText="{{ 'Flashpoint (in °C) ' | translate }}"></form-textbox>
                                </div>
                                <div *ngIf="showIMDG" class="col-xl-3 col-sm-6 col-xs-12">
                                    <form-textbox
                                        formControlName="cargoTechnicalDescription"
                                        labelText="{{ 'Cargo Technical Description' | translate }}"></form-textbox>
                                </div>
                                <div *ngIf="showIMDG" class="col-xl-3 col-sm-6 col-xs-12">
                                    <form-textbox
                                        formControlName="locationOnBoard"
                                        labelText="{{ 'Location On Board ' | translate }}"></form-textbox>
                                </div>
                                <div *ngIf="showIMDG" class="col-xl-3 col-sm-6 col-xs-12">
                                    <form-textbox
                                        formControlName="dgContact"
                                        labelText="{{ 'DG Contact' | translate }}"></form-textbox>
                                </div>
                                <div class="col-xl-3 col-sm-6 col-xs-12">
                                    <form-number
                                        formControlName="temperatureFrom"
                                        labelText="{{ 'Temperature From' | translate }}"
                                        [decimal]="true"></form-number>
                                </div>
                                <div class="col-xl-3 col-sm-6 col-xs-12">
                                    <form-number
                                        formControlName="temperatureTo"
                                        labelText="{{ 'Temperature To' | translate }}"
                                        [decimal]="true"></form-number>
                                </div>
                                <div class="col-xl-3 col-sm-6 col-xs-12">
                                    <form-dropdown
                                        formControlName="cargoCustomsStatusId"
                                        labelText="{{ 'Customs Status' | translate }}"
                                        codeListName="CustomsStatus"></form-dropdown>
                                </div>
                                <div class="col-xl-3 col-sm-6 col-xs-12">
                                    <form-checkbox
                                        labelText="{{ 'In Transit' | translate }}"
                                        formControlName="inTransit"></form-checkbox>
                                </div>
                                <div class="col-xl-3 col-sm-6 col-xs-12">
                                    <form-dropdown
                                        formControlName="portOfOriginId"
                                        labelText="{{ 'Port Of Origin' | translate }}"
                                        codeListName="Port"></form-dropdown>
                                </div>
                                <div class="col-xl-3 col-sm-6 col-xs-12">
                                    <form-dropdown
                                        formControlName="portOfLoadingId"
                                        labelText="{{ 'POL' | translate }}"
                                        codeListName="Port"></form-dropdown>
                                </div>
                                <div class="col-xl-3 col-sm-6 col-xs-12">
                                    <form-dropdown
                                        formControlName="portOfDischargeId"
                                        labelText="{{ 'POD' | translate }}"
                                        codeListName="Port"></form-dropdown>
                                </div>
                                <div class="col-xl-3 col-sm-6 col-xs-12">
                                    <form-dropdown
                                        formControlName="fpodId"
                                        labelText="{{ 'FPOD' | translate }}"
                                        codeListName="Port"></form-dropdown>
                                </div>
                                <div class="col-xl-3 col-sm-6 col-xs-12">
                                    <form-textbox
                                        formControlName="cargoShipper"
                                        labelText="{{ 'Shipper' | translate }}"></form-textbox>
                                </div>
                                <div class="col-xl-3 col-sm-6 col-xs-12">
                                    <form-textbox
                                        formControlName="cargoConsingnee"
                                        labelText="{{ 'Consignee' | translate }}"></form-textbox>
                                </div>
                                <div class="col-xl-3 col-sm-6 col-xs-12">
                                    <form-textbox
                                        formControlName="cargoNotify"
                                        labelText="{{ 'Notifier' | translate }}"></form-textbox>
                                </div>
                                <div class="col-xl-3 col-sm-6 col-xs-12">
                                    <form-textbox
                                        formControlName="customsDeclarationNumber"
                                        labelText="{{ 'Customs Declaration Number' | translate }}"></form-textbox>
                                </div>
                                <div class="col-xl-3 col-sm-6 col-xs-12">
                                    <form-textbox
                                        formControlName="customsDeclarationItemNumber"
                                        labelText="{{
                                            'Customs Declaration Item (Number)' | translate
                                        }}"></form-textbox>
                                </div>
                                <div class="col-xl-3 col-sm-6 col-xs-12">
                                    <form-textbox
                                        formControlName="customsArticleNumber"
                                        labelText="{{ 'Customs Article Number' | translate }}"></form-textbox>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="!containerized" class="disabledLabels">
                            <div class="row">
                                <div class="col-xl-3 col-sm-6 col-xs-12">
                                    <form-textbox
                                        formControlName="bookingNumber"
                                        labelText="{{ 'Booking Nbr.' | translate }}"></form-textbox>
                                </div>
                                <div class="col-xl-3 col-sm-6 col-xs-12">
                                    <form-textbox
                                        formControlName="billOfLadingNbr"
                                        labelText="{{ 'B/L Nbr.' | translate }}"></form-textbox>
                                </div>
                                <div class="col-xl-3 col-sm-6 col-xs-12">
                                    <form-textbox
                                        formControlName="taricCode"
                                        labelText="{{ 'TARIC Code' | translate }}"></form-textbox>
                                </div>
                                <div class="col-xl-3 col-sm-6 col-xs-12">
                                    <form-textbox
                                        formControlName="cargoDescription"
                                        labelText="{{ 'Cargo Description' | translate }}"></form-textbox>
                                </div>
                                <div class="col-xl-3 col-sm-6 col-xs-12">
                                    <form-textbox
                                        formControlName="cargoReference"
                                        labelText="{{ 'Cargo Reference (Marking)' | translate }}"></form-textbox>
                                </div>
                                <div class="col-xl-3 col-sm-6 col-xs-12">
                                    <form-dropdown
                                        formControlName="cargoRealizationTypeId"
                                        labelText="{{ 'Method Of Measurement' | translate }}"
                                        codeListName="CargoRealizationType"></form-dropdown>
                                </div>
                                <div class="col-xl-3 col-sm-6 col-xs-12">
                                    <form-number
                                        formControlName="numberOfPackages"
                                        labelText="{{ 'PCK. Number' | translate }}"></form-number>
                                </div>
                                <div class="col-xl-3 col-sm-6 col-xs-12">
                                    <form-dropdown
                                        formControlName="packagingTypeId"
                                        labelText="{{ 'PCK. Type' | translate }}"
                                        codeListName="CodeListCargoPackagingTypePerSystemForManifest"
                                        [forTOSSystem]="'CUST'"></form-dropdown>
                                </div>
                                <div class="col-xl-3 col-sm-6 col-xs-12">
                                    <form-dropdown
                                        formControlName="cargoTypePerSystemId"
                                        labelText="{{ 'Cargo Type' | translate }}"
                                        codeListName="CodeListCargoTypePerSystemForManifest"
                                        [forTOSSystem]="forTosSystem"></form-dropdown>
                                </div>
                                <div class="col-xl-3 col-sm-6 col-xs-12">
                                    <form-number
                                        formControlName="cargoNetWeight"
                                        labelText="{{ 'Net Weight (kg)' | translate }}"
                                        [decimal]="true"></form-number>
                                </div>
                                <div class="col-xl-3 col-sm-6 col-xs-12">
                                    <form-number
                                        formControlName="cargoGrossWeight"
                                        labelText="{{ 'Gross Weight (kg)' | translate }}"
                                        [decimal]="true"></form-number>
                                </div>
                                <div class="col-xl-3 col-sm-6 col-xs-12">
                                    <form-number
                                        formControlName="cargoVolume"
                                        labelText="{{ 'Volume (m3)' | translate }}"
                                        [decimal]="true"></form-number>
                                </div>
                                <div class="col-xl-3 col-sm-6 col-xs-12">
                                    <form-dropdown
                                        formControlName="mswCargoRegulationId"
                                        labelText="{{ 'DG Classification' | translate }}"
                                        codeListName="MswCargoRegulation"></form-dropdown>
                                </div>
                                <div *ngIf="form.controls.imdgClassId.enabled" class="col-xl-3 col-sm-6 col-xs-12">
                                    <form-dropdown
                                        formControlName="imdgClassId"
                                        labelText="{{ 'IMDG Nbr.' | translate }}"
                                        codeListName="ImdgClass"></form-dropdown>
                                </div>
                                <div *ngIf="form.controls.unNumberId.enabled" class="col-xl-3 col-sm-6 col-xs-12">
                                    <form-dropdown
                                        formControlName="unNumberId"
                                        labelText="{{ 'UN Nbr.' | translate }}"
                                        codeListName="UnNumber"></form-dropdown>
                                </div>
                                <div *ngIf="form.controls.packingGroupId.enabled" class="col-xl-3 col-sm-6 col-xs-12">
                                    <form-dropdown
                                        formControlName="packingGroupId"
                                        labelText="{{ 'Pack. Group' | translate }}"
                                        codeListName="ImdgPackingGroup"></form-dropdown>
                                </div>
                                <div *ngIf="form.controls.marpolCategoryId.enabled" class="col-xl-3 col-sm-6 col-xs-12">
                                    <form-dropdown
                                        formControlName="marpolCategoryId"
                                        labelText="{{ 'Marine Pollutant' | translate }}"
                                        codeListName="MarpolCategory"></form-dropdown>
                                </div>
                                <div *ngIf="form.controls.ibcCodeId.enabled" class="col-xl-3 col-sm-6 col-xs-12">
                                    <form-dropdown
                                        formControlName="ibcCodeId"
                                        labelText="{{ 'IBC Code' | translate }}"
                                        codeListName="IBCCode"></form-dropdown>
                                </div>
                                <div *ngIf="form.controls.imsbcCodeId.enabled" class="col-xl-3 col-sm-6 col-xs-12">
                                    <form-dropdown
                                        formControlName="imsbcCodeId"
                                        labelText="{{ 'IMSBC Code' | translate }}"
                                        codeListName="ImsbcCode"></form-dropdown>
                                </div>
                                <div *ngIf="form.controls.flashpoint.enabled" class="col-xl-3 col-sm-6 col-xs-12">
                                    <form-textbox
                                        formControlName="flashpoint"
                                        labelText="{{ 'Flashpoint (in °C) ' | translate }}"></form-textbox>
                                </div>
                                <div
                                    *ngIf="form.controls.cargoTechnicalDescription.enabled"
                                    class="col-xl-3 col-sm-6 col-xs-12">
                                    <form-textbox
                                        formControlName="cargoTechnicalDescription"
                                        labelText="{{ 'Cargo Technical Description' | translate }}"></form-textbox>
                                </div>
                                <div *ngIf="form.controls.locationOnBoard.enabled" class="col-xl-3 col-sm-6 col-xs-12">
                                    <form-textbox
                                        formControlName="locationOnBoard"
                                        labelText="{{ 'Location On Board ' | translate }}"></form-textbox>
                                </div>
                                <div *ngIf="form.controls.dgContact.enabled" class="col-xl-3 col-sm-6 col-xs-12">
                                    <form-textbox
                                        formControlName="dgContact"
                                        labelText="{{ 'DG Contact' | translate }}"></form-textbox>
                                </div>
                                <div class="col-xl-3 col-sm-6 col-xs-12">
                                    <form-dropdown
                                        formControlName="cargoCustomsStatusId"
                                        labelText="{{ 'Customs Status' | translate }}"
                                        codeListName="CustomsStatus"></form-dropdown>
                                </div>
                                <div class="col-xl-3 col-sm-6 col-xs-12" style="margin-top: 10px">
                                    <form-checkbox
                                        labelText="{{ 'In Transit' | translate }}"
                                        formControlName="inTransit"></form-checkbox>
                                </div>
                                <div class="col-xl-3 col-sm-6 col-xs-12">
                                    <form-dropdown
                                        formControlName="portOfOriginId"
                                        labelText="{{ 'Port Of Origin' | translate }}"
                                        codeListName="Port"></form-dropdown>
                                </div>
                                <div class="col-xl-3 col-sm-6 col-xs-12">
                                    <form-dropdown
                                        formControlName="portOfLoadingId"
                                        labelText="{{ 'POL' | translate }}"
                                        codeListName="Port"></form-dropdown>
                                </div>
                                <div class="col-xl-3 col-sm-6 col-xs-12">
                                    <form-dropdown
                                        formControlName="portOfDischargeId"
                                        labelText="{{ 'POD' | translate }}"
                                        codeListName="Port"></form-dropdown>
                                </div>
                                <div class="col-xl-3 col-sm-6 col-xs-12">
                                    <form-dropdown
                                        formControlName="fpodId"
                                        labelText="{{ 'FPOD' | translate }}"
                                        codeListName="Port"></form-dropdown>
                                </div>
                                <div class="col-xl-3 col-sm-6 col-xs-12">
                                    <form-textbox
                                        formControlName="cargoShipper"
                                        labelText="{{ 'Shipper' | translate }}"></form-textbox>
                                </div>
                                <div class="col-xl-3 col-sm-6 col-xs-12">
                                    <form-textbox
                                        formControlName="cargoConsingnee"
                                        labelText="{{ 'Consignee' | translate }}"></form-textbox>
                                </div>
                                <div class="col-xl-3 col-sm-6 col-xs-12">
                                    <form-textbox
                                        formControlName="cargoNotify"
                                        labelText="{{ 'Notifier' | translate }}"></form-textbox>
                                </div>
                                <div class="col-xl-3 col-sm-6 col-xs-12">
                                    <form-textbox
                                        formControlName="customsDeclarationNumber"
                                        labelText="{{ 'Customs Declaration Number' | translate }}"></form-textbox>
                                </div>
                                <div class="col-xl-3 col-sm-6 col-xs-12">
                                    <form-textbox
                                        formControlName="customsDeclarationItemNumber"
                                        labelText="{{
                                            'Customs Declaration Item (Number)' | translate
                                        }}"></form-textbox>
                                </div>
                            </div>
                        </div>
                    </form>
                </ng-template>
            </app-loader>
        </div>

        <div class="modal-footer">
            <button class="btn btn-sm btn-secondary" (click)="activeModal.close(false)">
                <fa-icon [icon]="['fas', 'times']"></fa-icon>
                &nbsp;&nbsp;{{ 'Cancel' | translate }}
            </button>
            <button class="btn btn-sm btn-primary" [disabled]="getIsFormDisabled" (click)="saveCargoItem()">
                <fa-icon [icon]="['fas', 'check']"></fa-icon>
                &nbsp;&nbsp;{{ isEditMode ? 'Save' : ('Add' | translate) }}
            </button>
        </div>
    </ng-template>
</app-loader>
