<div class="fixed-background login-page" id="imageDiv" [hidden]="!showBackgroundImage">
    <div class="login-wrap" [ngClass]="{ 'login-mobile-wrap': isMobile }">
        <div class="login-html" [ngClass]="{ 'login-html-size': isMobile }">
            <!-- <span class="row">
                <button class="btn btn-sm btn-danger ml-3" *ngIf="usernameAndPasswordMode" (click)="toggleSignInMode()" type="button">
                </button>
            </span> -->
            <div class="row title" [ngClass]="{ 'login-image-padding': !isMobile }">
                <span *ngIf="!isPasswordExpired" class="col-12 d-flex align-items-center justify-content-center">
                    <span class="back-icon" (click)="toggleSignInMode()">
                        <fa-icon *ngIf="usernameAndPasswordMode" [icon]="['fas', 'arrow-left']"></fa-icon>
                    </span>
                    <span class="mx-auto">{{ 'Sign In' | translate }}</span>
                </span>
                <span *ngIf="isPasswordExpired" class="col-12 d-flex align-items-center justify-content-center">
                    <span class="back-icon" (click)="cancelResetPasswordMode()">
                        <fa-icon [icon]="['fas', 'arrow-left']"></fa-icon>
                    </span>
                    <span class="mx-auto">{{ 'Reset Password' | translate }}</span>
                </span>
            </div>
            <app-loader [isBusy]="false">
                <ng-template>
                    <div *ngIf="!oidcEnabled" class="login-form">
                        <form *ngIf="firstFactorStage" [formGroup]="firstFactorForm" (ngSubmit)="initiateLogin()">
                            <div
                                *ngIf="usernameAndPasswordMode"
                                class="sign-in-htm"
                                [ngClass]="{ 'login-mobile-width': isMobile }">
                                <div class="group">
                                    <label class="label" for="user">{{ 'Username' | translate }}</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="fa fa-user"></i></span>
                                        <form-textbox class="input-width" formControlName="username"></form-textbox>
                                    </div>
                                </div>
                                <div class="group">
                                    <label class="label" for="pass">{{ 'Password' | translate }}</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="fa fa-key"></i></span>
                                        <form-textbox
                                            class="input-width"
                                            formControlName="password"
                                            [textboxType]="'password'"></form-textbox>
                                    </div>
                                </div>
                                <div class="group">
                                    <span>
                                        <input type="checkbox" formControlName="tac" #notification kendoCheckBox />
                                        {{ 'I have read and agree with the' | translate }}
                                        <a
                                            *ngIf="translateService.currentLang === 'en'"
                                            class="tac-checkbox"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href="http://www.bgports.bg/en/page/78">
                                            {{ 'Terms & Conditions and the Privacy Policy' | translate }}
                                        </a>
                                        <a
                                            *ngIf="translateService.currentLang === 'bg'"
                                            class="tac-checkbox"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href="http://www.bgports.bg/bg/page/78">
                                            {{ 'Terms & Conditions and the Privacy Policy' | translate }}
                                        </a>
                                    </span>
                                </div>

                                <div class="group">
                                    <button
                                        class="btn-md btn-primary btn-block button"
                                        type="submit"
                                        translate
                                        [disabled]="getIsFirstFactorDisabled">
                                        Sign In
                                    </button>
                                </div>
                                <div class="hr"></div>
                                <div class="row">
                                    <div class="col-sm-12 col-md-6 col-lg-6 foot-lnk">
                                        <a translate [routerLink]="['/createUser']">Sign up</a>
                                    </div>
                                    <div class="col-sm-12 col-md-6 col-lg-6 float-right foot-lnk">
                                        <a translate [routerLink]="['/forgotPassword']">Forgot Password?</a>
                                    </div>
                                </div>
                                <div class="hr"></div>
                                <div *ngIf="translateService.currentLang == 'bg'" class="row">
                                    <div class="col-sm-12 col-md-12 col-lg-12 foot-lnk text-center mt-2">
                                        <a class="downloadlink" (click)="downloadRegistrationForm()">
                                            Заявление за достъп
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div
                                *ngIf="certificateMode"
                                class="sign-in-htm mt-5"
                                [ngClass]="{ 'login-mobile-width': isMobile }">
                                <div *ngIf="false" class="group">
                                    <button
                                        class="btn-md btn-primary btn-block button"
                                        type="button"
                                        translate
                                        (click)="openBgSSO(true)">
                                        SLO DEMO SSO
                                    </button>
                                </div>
                                <div *ngIf="certificateLoginEnabled" class="group">
                                    <button
                                        class="btn-md btn-primary btn-block button"
                                        type="button"
                                        translate
                                        (click)="openBgSSO()">
                                        Login - Certificate
                                    </button>
                                </div>
                                <div class="group">
                                    <button
                                        *ngIf="certificateMode"
                                        class="btn-md btn-primary btn-block button"
                                        type="button"
                                        translate
                                        (click)="toggleSignInMode()">
                                        Login - Username & Password
                                    </button>
                                </div>

                                <div class="hr"></div>

                                <div class="row">
                                    <div class="col-sm-12 col-md-6 col-lg-6 foot-lnk">
                                        <a translate [routerLink]="['/createUser']">Sign up</a>
                                    </div>
                                    <div class="col-sm-12 col-md-6 col-lg-6 float-right foot-lnk">
                                        <a translate [routerLink]="['/forgotPassword']">Forgot Password?</a>
                                    </div>
                                </div>
                                <div class="hr"></div>
                                <div *ngIf="translateService.currentLang == 'bg'" class="row">
                                    <div class="col-sm-12 col-md-12 col-lg-12 foot-lnk text-center mt-2">
                                        <a class="downloadlink" (click)="downloadRegistrationForm()">
                                            Заявление за достъп
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <form *ngIf="resetPasswordStage" [formGroup]="resetPasswordForm" (ngSubmit)="resetPassword()">
                            <div
                                *ngIf="isPasswordExpired"
                                class="sign-in-htm"
                                [ngClass]="{ 'login-mobile-width': isMobile }">
                                <p>{{ 'Your password has expired and needs to be changed.' | translate }}</p>
                                <div class="group">
                                    <label class="label" for="pass">{{ 'Current Password' | translate }}</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="fa fa-key"></i></span>
                                        <form-textbox
                                            class="input-width"
                                            formControlName="currentPassword"
                                            [textboxType]="'password'"></form-textbox>
                                    </div>
                                </div>
                                <div class="group">
                                    <label class="label" for="pass">{{ 'New Password' | translate }}</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="fa fa-key"></i></span>
                                        <form-textbox
                                            class="input-width"
                                            formControlName="newPassword"
                                            contextual="{{
                                                'Minimum of 9 characters including lower and capital letter, a number, and a special character.'
                                                    | translate
                                            }}
                                            {{
                                                'Password should be unique, do not use the same password for multiple systems.'
                                                    | translate
                                            }}
                                            {{
                                                'Password should be complex, do not use name, surname, pcs, birthdays or 123 in your password.'
                                                    | translate
                                            }}
                                            {{
                                                'Password should be made out of 2 or more unrelated words or random characters, more meaning a safer password.'
                                                    | translate
                                            }}"
                                            [textboxType]="'password'"></form-textbox>
                                    </div>
                                </div>
                                <div class="group">
                                    <label class="label" for="pass">{{ 'Confirm password' | translate }}</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="fa fa-key"></i></span>
                                        <form-textbox
                                            class="input-width"
                                            formControlName="confirmPassword"
                                            [textboxType]="'password'"></form-textbox>
                                    </div>
                                </div>
                                <div class="row"></div>
                                <div class="hr"></div>

                                <div class="test">
                                    <div class="group">
                                        <button
                                            class="btn-md btn-primary btn-block button"
                                            type="submit"
                                            translate
                                            [disabled]="!resetPasswordForm.valid">
                                            Reset Password
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>

                        <form *ngIf="twoFactorStage" [formGroup]="secondFactorForm" (ngSubmit)="logIn()">
                            <div class="form-signin" [ngClass]="{ 'login-mobile-width': isMobile }">
                                <form-number
                                    labelText="{{ '2-Factor code' | translate }}"
                                    formControlName="twoFactorCode"
                                    (paste)="onPaste($event)"></form-number>

                                <div class="group">
                                    <button
                                        class="btn btn-md btn-primary btn-block button mt-3"
                                        type="submit"
                                        translate
                                        [disabled]="isBusy || !secondFactorForm.valid">
                                        Sign In
                                    </button>
                                    <button
                                        class="btn btn-md btn-primary btn-block button mt-3"
                                        type="button"
                                        translate
                                        (click)="setActiveAuthStage('ChooseTwoFactorPreference')">
                                        Change 2-Factor Type
                                    </button>
                                </div>

                                <div *ngIf="showResendCodeButton" class="text-center resend-code">
                                    <a translate (click)="resendCode()">Resend code</a>
                                </div>
                            </div>
                        </form>

                        <div *ngIf="chooseTwoFactorStage">
                            <form class="form-2fa" [ngClass]="{ 'login-mobile-width': isMobile }">
                                <div class="row two-factor-selection">
                                    <div class="col-md-8">
                                        <h2 translate>None</h2>
                                        <p translate>Two factor is disabled.</p>
                                    </div>

                                    <div class="col-md-4 text-center group -contjustifyent-center">
                                        <button
                                            class="btn btn-md btn-primary button select-2fa"
                                            type="button"
                                            translate
                                            (click)="setUser2FaPreference('DISABLED')">
                                            Select
                                        </button>
                                    </div>
                                </div>

                                <div class="row two-factor-selection">
                                    <div class="col-md-8">
                                        <h2 translate>Email</h2>
                                        <p translate>Verification code will be sent via email.</p>
                                    </div>

                                    <div class="col-md-4 text-center group">
                                        <button
                                            class="btn btn-md btn-primary button select-2fa"
                                            type="button"
                                            translate
                                            (click)="setUser2FaPreference('EMAIL')">
                                            Select
                                        </button>
                                    </div>
                                </div>

                                <div class="row two-factor-selection">
                                    <div class="col-md-8">
                                        <h2 translate>Certificate</h2>
                                        <p translate>User will be verified with personal certificate.</p>
                                    </div>

                                    <div class="col-md-4 text-center group -contjustifyent-center">
                                        <button
                                            class="btn btn-md btn-primary button select-2fa"
                                            type="button"
                                            translate
                                            (click)="setUser2FaPreference('CERTIFICATE')">
                                            Select
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div *ngIf="oidcEnabled" class="login-form">
                        <div class="sign-in-htm" [ngClass]="{ 'login-mobile-width': isMobile }">
                            <div class="login-title" translate>Access PCS - Port Community System</div>
                            <div class="group">
                                <button class="btn-md btn-primary btn-block button" translate (click)="oidcLogin()">
                                    Continue to login
                                </button>
                            </div>
                            <div class="hr"></div>
                            <div class="row">
                                <div class="col-sm-12 col-md-12 col-lg-12 foot-lnk">
                                    <a translate [routerLink]="['/createUser']">Sign up</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </app-loader>
            <div class="row">
                <div class="col">
                    <img *ngIf="translateService.currentLang === 'en'" src="../../../../assets/img/eu.svg" alt="EU" />
                    <img *ngIf="translateService.currentLang === 'bg'" src="../../../../assets/img/eu2.svg" alt="EU" />
                </div>
                <div class="col">
                    <img
                        *ngIf="translateService.currentLang === 'en'"
                        src="../../../../assets/img/trasnport2.svg"
                        alt="Transport" />
                    <img
                        *ngIf="translateService.currentLang === 'bg'"
                        src="../../../../assets/img/transport.svg"
                        alt="Transport" />
                </div>
            </div>
            <div *ngIf="translateService.currentLang === 'en'" class="row mt-2 text-center bottom-text">
                <div class="col-12">
                    The web portal is developed and maintained under project Development and implementation of a system
                    for electronic exchange of information in the Bulgarian ports (Port Community System – PCS)
                </div>
            </div>
            <div *ngIf="translateService.currentLang === 'bg'" class="row mt-2 text-center bottom-text">
                <div class="col-12">
                    Тази интернет страница е разработена по проект Разработване и внедряване на система за електронен
                    обмен на информация в българските пристанища (Port Community System – PCS)
                </div>
            </div>
        </div>
    </div>
</div>
